.machine-container {
	position: relative;
	min-height: calc(100vh - 64px);
}

.machine-container-loading {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	min-height: calc(100vh - 64px);
}

.loading-spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	transition: all 0.3s ease-in;
	z-index: 1000;
}

.loading-spinner-inactive {
	opacity: 0;
	z-index: -1000;
}

@keyframes p-progress-spinner-color {
	0% {
		stroke: var(--color-dark-green);
	}
	50% {
		stroke: var(--color-light-green);
	}
	100% {
		stroke: var(--color-dark-green);
	}
}
