.header-menu {
    margin: 3rem 0;   
}

@media (min-width: 600px) {
    .header-menu {
        margin: 0 0 3rem 0;
    }
}

.header-menu h3 {
    margin: 0;
    background-color: var(--color-dark-green);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
}

.menu {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

div.p-tabview-nav {
    margin-left: 4rem !important;
}