@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif !important;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


/* make container class like tailwind */

.layout-container {
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin: 0.5rem;
  background-color: white;
  border-radius: 1rem;
  padding: 2rem 1rem;
  padding-top: 1rem;
  max-width: 100%;
  max-height: calc(100vh - 1rem);
  min-height: calc(100vh - 1rem);
  overflow-y: scroll;
}

@media (min-width: 1200px) {
  .layout-container {
    padding: 2rem;
    padding-top: 2rem;
    margin-left: 0;
    max-width: calc(100% - 18.5rem);
  }
}

@media (min-width: 1400px) {
  .layout-container {
    padding: 2rem;
    padding-top: 2rem;
    margin-left: 0;
    max-width: calc(100% - 25.5rem);
  }
}

.layout-container--login {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin: 1rem;
  background-color: white;
  border-radius: 1rem;
  padding: 2rem 1rem;
  padding-top: 1rem;
  max-width: 100%;
  max-height: calc(100vh - 2rem);
  min-height: calc(100vh - 2rem);
  overflow-y: auto;
}
.App {
	text-align: center;
	height: 100%;
}

.App-logo {
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #005c64;
}

.Main {
	min-height: 100vh;
	display: container;
	flex-flow: column nowrap;
}

.login-column {
	background-color: #005c64 !important;
}
.container_table {
	height: 290px;
	overflow-y: scroll; /* or auto */
}

.flex-container {
	display: flex;
	flex-direction: row;
	background-image: url(/static/media/nav-bg.af1f3dae.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom;
	position: relative;
}

.nav-sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	height: 100vh;
	/* background-image: url('./assets/nav-bg.png'); */
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	display: none;
}

@media (min-width: 1200px) {
	.nav-sticky {
		display: block;
	}
}

.hamburger {
	position: absolute;
	top: 1rem;
	left: 1rem;
	z-index: 2;
}

@media (min-width: 1200px) {
	.hamburger {
		display: none;
	}
}

.login {
    position: absolute;
    height: 100vh;
    width: 100vw;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.login-button {
    text-align: center !important;
    width: 70% !important; 
    margin: 1em auto !important;
    border-radius: 24px !important;
    justify-content: center !important; 
    display: flex !important;
    background-color: #FEF8E3 !important;
}
.login-card{
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    background-color: #005C64 !important;
    border-radius: 24px !important;
}

.login-image{
    margin-top: 10px;
}

.login-header{
    color: #FEF8E3 !important;
}
:root {
	--color-dark-green: #005c64;
	--color-light-green: #00a19a;
	--color-light-red: #ff6b6b;
	--color-dark-grey: #5e5e5e;
	--color-light-grey: #e5e5e5;
	--color-black: #000000;
	--color-soup: #fc7033;
	--color-smoothie: #005c64;
	--color-frappe: #7C463C;
}

.nav {
    min-width: 18rem;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

@media (min-width: 1200px) {
    .nav {
        min-width: 20rem;
    }
}

@media (min-width: 1400px) {
    .nav {
        min-width: 25rem;
    }
}

.nav-header {
    margin: 0 1rem 1rem 1rem;
    border-bottom: 1px solid var(--color-dark-green)
}

.nav-header--logo {
    margin: 2rem 0;
    margin-left: 1rem;
    width: 10rem;
    height: auto;
}

.nav-list {
    list-style: none;
    margin: 0;
    padding: 0 1rem;
    margin-top: 2rem;
}

.nav-list-item {
    position: relative;
    margin-bottom: 0.5rem;
    border: 2px solid transparent;
    transition: all 0.2s ease-in-out;
}

.nav-list-item:hover {
    border-radius: 0.25rem;
    background-color: #00a19931;
}

.nav-list-item a {
    padding: .75rem 0;
    height: 100%;
    width: 100%;
    display: block;
    padding-left: 3rem;
}

.nav-list-item--icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: white;
}

.nav-list-item--active {
    background-color: #00a19931;
    border-radius: 0.25rem;
    border: 2px solid var(--color-light-green);
}

.nav-list-item--active::before {
    content: '';
    height: 70%;
    background-color: var(--color-light-green);
    width: 0.2rem;
    position: absolute;
    left: -1rem;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0 0.25rem 0.25rem 0;
}

.nav-list-item a {
    color: white;
    text-decoration: none;
}

.nav-user {
    margin: 1rem 1rem 0 1rem;
    border-top: 1px solid var(--color-dark-green);
    padding-top: 1rem;
}

.nav-user--container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-user--inner-container {
    display: flex;
    align-items: center;
}

.nav-user--name {
    color: white;
    font-weight: bold;
    margin-bottom: 0.25rem;
}

.nav-user--role {
    text-transform: capitalize;
    color: white;
    font-size: 0.8rem;
}

.nav-user--avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-right: 1rem;
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: white;
    font-weight: bold;
}

.menu-mobile {
    margin-top: 1rem;
}

div.hamburger {
    top: 1.5rem !important;
    right: 1.5rem !important;
    left: auto !important;
}


@media (min-width: 1200px) {
    .menu-mobile {
        display: none;
    }
}
.machine-stats {
	margin-top: 20px;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 20px;
}

@media (min-width: 375px) {
	.machine-stats {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (min-width: 768px) {
	.machine-stats {
		grid-template-columns: repeat(3, 1fr);
	}
}

.machine-stats-card {
	padding: 16px;
	border-radius: 10px;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(61, 61, 61, 0.089);
	border: 1px solid var(--color-light-grey);
	height: 178.175px;
}

.filter-active {
	border: 1px solid var(--color-dark-green);
}

.machine-stats-card-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

.machine-stats-card-header h3 {
	margin: 0;
}

.machine-stats-card-status {
	margin-bottom: 4px;
	cursor: pointer;
	opacity: 0.9;
	transition: all 0.1s ease-in;
}

.machine-stats-card-status:hover {
	opacity: 1;
}

.machine-stats-card-status:hover .machine-stats-card-status-flex p {
	color: var(--color-black);
}

.machine-stats-card-status:hover .machine-stats-card-status-flex p:first-child {
	transform: translateX(3px);
}

.machine-stats-card-status:hover progress {
	scale: 1.02;
}

.machine-stats-card-status-flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.machine-stats-card-status-flex p {
	margin: 0;
	color: var(--color-dark-grey);
	font-weight: 500;
	font-size: 12px;
	margin-bottom: -4px;
	transition: all 0.1s ease-in;
}

progress {
	border: 0;
	height: 6px;
	width: 100%;
	border-radius: 200px;
	background-color: #fefefe;
	transition: all 0s ease-in;
}
progress::-webkit-progress-bar {
	border: 0;
	height: 6px;
	width: 100%;
	border-radius: 200px;
	background-color: #e6e6e6;
}
progress::-webkit-progress-value {
	border: 0;
	height: 6px;
	width: 100%;
	border-radius: 200px;
	background-color: #e6e6e6;
}
progress::-moz-progress-bar {
	border: 0;
	height: 6px;
	width: 100%;
	border-radius: 200px;
	background-color: #e6e6e6;
}

.progress::-webkit-progress-value {
	background-color: var(--color-dark-green);
}

.progress {
	background-color: #2196f3;
}

.progress-offline::-webkit-progress-value {
	background-color: var(--color-light-red);
}
.progress-offline {
	background-color: var(--color-light-red);
}

.progress-online::-webkit-progress-value {
	background-color: var(--color-light-green);
}
.progress-online {
	background-color: var(--color-light-green);
}

.clear-filter-button {
	cursor: pointer;
}

.clear-filter-button i {
	color: var(--color-dark-grey);
	transition: all 0.15s ease-in;
}

.clear-filter-button:hover i {
	transform: rotate(180deg);
	transform-origin: center;
	color: var(--color-light-red);
}

.action-bar {
	margin: 16px 0;
	flex-direction: column;
	align-items: flex-start;
}

@media (min-width: 768px) {
	.action-bar {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
}

.action-bar-search-group {
	display: flex;
	align-items: center;
	border-radius: 4px;
	/* box-shadow: 0 0 10px rgba(61, 61, 61, 0.089); */
	border: 1px solid var(--color-light-grey);
	width: 100%;
	margin-right: 16px;
}

.action-bar-search-group-active-filters {
	display: flex;
	align-items: center;
	padding: 2px;
	padding-right: 8px;
	margin-left: 8px;
	border-right: 1px solid var(--color-light-grey);
}

.tag {
	margin-right: 4px;
	text-transform: capitalize;
	font-weight: 400;
	font-size: 10px;
	border-radius: 4px;
	padding: 1px 6px;
	white-space: nowrap;
}

.tag-label {
	display: none;
}

@media (min-width: 768px) {
	.tag-label {
		display: inline;
	}
}

.action-bar-search {
	display: flex;
	align-items: center;
	padding: 2px 8px;
	width: 100%;
}

.action-bar-search i {
	color: var(--color-dark-grey);
}

.action-bar-search input {
	border: none;
	padding: 8px;
	font-size: 14px;
	color: var(--color-dark-grey);
	width: 100%;
}

.action-bar-search input:focus {
	border: none;
	outline: none;
}

.action-bar-totals {
	margin-top: 16px;
}

@media (min-width: 768px) {
	.action-bar-totals {
		margin-top: 0;
		white-space: nowrap;
	}
}

.action-bar-totals p {
	font-size: 12px;
	color: var(--color-dark-grey);
	font-weight: 500;
}

.table-flex-column {
	display: flex;
	flex-direction: column;
}

.table-flex-row {
	display: flex;
	align-items: center;
}

.table-status-text {
	font-size: 10px;
	font-weight: 500;
	margin-left: 5px;
}

.table-cell {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 5rem;
}

.table-customer-name {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: -5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.table-price {
	font-size: 12px;
	font-weight: 500;
}

.table-name {
	font-size: 9px;
	font-weight: 400;
}

.table-version {
	font-size: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.table-tag {
	font-size: 9px;
	font-weight: 400;
	padding: 1px 4px;
}

.p-column-title {
	font-size: 12px;
	font-weight: 500;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.p-column-header-content span svg {
	height: 10px;
}

.p-datatable-wrapper {
	border-radius: 4px;
}

.machine-container {
	position: relative;
	min-height: calc(100vh - 64px);
}

.machine-container-loading {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	min-height: calc(100vh - 64px);
}

.loading-spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	transition: all 0.3s ease-in;
	z-index: 1000;
}

.loading-spinner-inactive {
	opacity: 0;
	z-index: -1000;
}

@-webkit-keyframes p-progress-spinner-color {
	0% {
		stroke: var(--color-dark-green);
	}
	50% {
		stroke: var(--color-light-green);
	}
	100% {
		stroke: var(--color-dark-green);
	}
}

@keyframes p-progress-spinner-color {
	0% {
		stroke: var(--color-dark-green);
	}
	50% {
		stroke: var(--color-light-green);
	}
	100% {
		stroke: var(--color-dark-green);
	}
}

.container-details {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.info-section {
	height: 100%;
	width: 100%;
}

.hero-container {
	width: 100%;
	height: 15rem;
	position: relative;
	border-radius: 1rem;
	overflow: hidden;
}

.hero {
	border-radius: 0.5rem;
	overflow: hidden;
	position: relative;
	height: 100%;
	width: 100%;
	background-size: 90%;
	background-position: -170% 10%;
	background-repeat: no-repeat;
	z-index: 2;
}

.hero-color {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	background-image: linear-gradient(120deg, white  10%, var(--color-dark-green) 80%);
}

.hero-color-smoothie {
	background-image: linear-gradient(120deg, white  10%, var(--color-smoothie) 80%);
}

.hero-color-soup {
	background-image: linear-gradient(120deg, white  10%, var(--color-soup) 80%);
}

.hero-color-frappe {
	background-image: linear-gradient(120deg, white  10%, var(--color-frappe) 80%);
}

.hero-color-combo {
	background-image: linear-gradient(120deg, white  10%, var(--color-smoothie) 80%);
}

.hero-content {
	position: absolute;
	top: 2rem;
	right: 2rem;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: flex-end;
	z-index: 3;
}

.hero-content h1, .hero-content p {
	color: white;
	text-align: right;
	
}

.hero-content h1 {
	font-size: 1.8rem;
}

.smoothie {
	background-image: url(/static/media/smoothie.2023c921.png);
}	

.soup {
	background-image: url(/static/media/soup.c68154f1.png);
	
}

.frappe {
	background-image: url(/static/media/frappe.a7bbeb63.png);
}

.combo {
	background-image: url(/static/media/combo.2023c921.png);
}

.general-info {
	width: 100%;
	margin-top: 2rem;
	margin-bottom: 4rem;
	border-radius: 5px;
	min-width: 350px;
	width: 100%;
}

h2 {
	border-bottom: 1px solid #e7e7e7;
	padding-bottom: 1rem;
	font-size: 1.4rem;
}

.container-form {
	width: 100%;
	border-radius: 5px;
}

.ui.toggle.checkbox {
	min-height: 2.5rem;
	display: flex;
	align-items: center;
}

.info-flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 1rem;
	font-size: 1rem;
	padding: 0.25rem 0;
	border-radius: 5px;
	color: var(--color-dark-grey);
}

.info-flex span:first-child,
.info-flex-location-title {
	font-weight: bold;
}

.info-flex-location {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	font-size: 1rem;
	background-color: #e0e0e0;
	padding: 0.75rem 0.5rem;
	border-radius: 5px;
}

.info-flex-content {
	display: flex;
	align-items: center;
}

.info-flex-content span {
	margin-right: 0.5rem;
}

.info-flex-location-edit {
	cursor: pointer;
	margin-right: 0.5rem;
	padding: 0.25rem;
	border-radius: 50%;
	transition: all 0.3s ease-in-out;
}

.info-flex-location-edit:hover {
	background-color: #ccc;
	color: #fff;
}

@media (min-width: 1000px) {
	.info-section {
		border-right: 1px solid #e7e7e7;
		padding-right: 1rem;
	}

	.container-details {
		display: grid;
		grid-template-columns: 1fr 3fr;
		grid-template-rows: 1fr;
		grid-gap: 1rem;
		align-items: flex-start;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	.general-info {
		margin-top: 2;
	}

	.container-form {
		position: relative;
		width: 100%;
	}
}

.copy-id-flex {
	display: flex;
	align-items: center;
}

.copy-id-flex #machineId {
	margin-right: 0.5rem;
	font-weight: normal;
}

.copy-icon {
	cursor: pointer;
}

@media (min-width: 1400px) {
	.container-details {
		display: grid;
		grid-template-columns: 1fr 4fr;
		grid-template-rows: 1fr;
		grid-gap: 1rem;
		align-items: flex-start;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	.general-info {
		flex-basis: 40%;
		/* height: 100%; */
		margin-top: 2;
	}

	.container-form {
		position: relative;
		width: 100%;
	}
}

.video-placeholder{
    width: 30px;
    height: 100px;
}
ul.p-picklist-list {
    max-height: calc(100vh - 20rem);
    min-height: 20rem !important;
}

.p-picklist-target li.p-picklist-item {
    /* overflow: initial !important; */
    /* padding: 1.25rem 1.25rem !important; */
}
.p-picklist-target li.p-picklist-item::marker {
    font-weight: bold;
}

.machine-save-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
}

@media (min-width: 1200px) {
    .machine-save-button {
        margin-right: 5.5rem;
    }
}

.ingredients {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.ingredient-chip {
    margin: 0.5rem 0.25rem;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.ingredient-chip:hover {
    background-color: #d6d6d6;
    box-shadow: 0 0 0 1px #d6d6d6;
}

.ingredient-chip--highlight--error {
    background-color: #f860608c;
    border: 1px solid #f86060;
} 

.ingredient-chip--highlight {
    background-color: #609af88c;
    border: 1px solid #609af8;
}

.ingredient-chip--clear-filter {
    background-color: var(--color-dark-green);
    border: 1px solid var(--color-dark-green);
    color: #fff;
}

.ingredient-chip--clear-filter:hover {
    background-color: var(--color-light-green);
    border: 1px solid var(--color-light-green);
}

.recipe-item {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 0.5rem;
    border: 1px solid #e0e0e0;
}

.recipe-item--error {
    border: 1px solid #f86060;
    color: #f86060;
}

.recipe-item-image {
    width: 100%;
    max-width: 9rem;
    height: 9rem;
    overflow: hidden;
    position: relative;
    background-color: #e0e0e0;

}

.missing-ingredients {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 1rem);
}

.recipe-item-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.recipe-content {
    height: 9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 1rem 0.5rem;
    position: relative;
}

.recipe-content-title {
    font-size: 1rem;
    font-weight: 500;
}

.recipe-content-category {
    font-size: 0.8rem;
    color: #999;
}

.recipe-content-category::after {
    content: " | ";
    margin: 0 0.15rem;
}

.recipe-content-category:last-child::after {
    content: "";
    margin: 0;
}

.recipe-content-label {
    position: absolute;
    z-index: 1;
    right: 5.5rem;
    top: 2rem;
    width: auto;
    height: 2rem;
    border-radius: 0.25rem;
}

.recipe-content-label img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.recipe-item-action {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 1;
}

.header-menu {
    margin: 3rem 0;   
}

@media (min-width: 600px) {
    .header-menu {
        margin: 0 0 3rem 0;
    }
}

.header-menu h3 {
    margin: 0;
    background-color: var(--color-dark-green);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
}

.menu {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

div.p-tabview-nav {
    margin-left: 4rem !important;
}
.container-screenshot {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	width: 100%;
	height: 100%;
}

.screenshot {
	width: 100%;
	height: 100%;
	object-fit: contain;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
}



.form{
    margin-left: 5px;
}
.uploadedImage {
	height: 100%;
	width: 100%;
}

.placeholderImage {
	min-height: 150px;
}

.uploadedImage-image {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

@media screen and (min-width: 768px) {
	.uploadedImage {
		height: 250px;
		width: 250px;
	}
}

.disabled-input {
	background-color: #f0f0f0;
	border: 1px solid #dcdcdc;
	color: #2e2e2e;
}

.current-page-report-template {
    /* color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' */
    font-size: 12px;
    color: var(--color-dark-grey);
    font-weight: 500;
}

.all-recipe-datatable {
    border-top: 1px solid #c3cedbb0;
}

p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
    height: 2rem !important;
    min-width: 2rem !important;
}

.clear-filter-button {
    height: 2rem !important;
    width: 2rem !important;
    padding: 0.5rem !important;
}

.clear-filter-button span {
    color: var(--color-dark-grey);
}
.recipe-template-container {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
}

.recipe-template-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.recipe-template-image--fallback {
    height: 70%;
    width: 70%;
    background-color: var(--color-dark-grey);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 0.5;
}

.recipe-template-name {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}

.recipe-template-content-container {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 15rem;
}

.recipe-template-description-container-description {
    font-size: 12px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.recipe-template-label-container {
    width: 50px;
}

.recipe-template-label-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.recipe-template-label-container span {
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
}

.recipes-template-categories {
    font-size: 9px;
	font-weight: 400;
	padding: 1px 4px;
    border-radius: 4px;
    text-transform: capitalize;
    margin-right: 5px;
}

.recipe-ingredient-template-image {
    width: 25px;
    height: 25px;
    object-fit: cover;
    border-radius: 50%;
}

.recipe-ingredient-template-image--fallback {
    width: 27px;
    height: 27px;
    background-color: var(--color-light-grey);
    border-radius: 50%;
    font-size: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-dark-grey);
    line-height: 1;
    text-align: center;
}



.ingredient-template-container {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
}

.ingredient-template-color {
    opacity: 0.2;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.ingredient-template-icon {
    width: 80%;
    height: 80%;
    object-fit: cover;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.ingredient-allowed-in-template-tag {
    font-size: 9px;
	font-weight: 400;
	padding: 1px 4px;
    border-radius: 4px;
    text-transform: capitalize;
    margin-right: 5px;
}

.ingredient-template-name {
    font-size: 14px;
    font-weight: 500;
}

.ingredient-template-percentage {
	font-size: 12px;
	font-weight: 500;
}

.highlighted-row {
    background-color: #c7d2fe8c !important;
}
.movies-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.movies-list-item {
    margin-bottom: 1rem;
    margin: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-color: #f5f5f5;
    border-radius: 0.5rem;
    border: 1px solid #e0e0e0;
    overflow: hidden;
}

.movies-list-item span {
    width: 180px;
    padding: .25rem 0.5rem 0;
}
.main-datatable {
	border-right: 1px solid #c3cedbb0;
	border-left: 1px solid #c3cedbb0;
	border-radius: 10px;
	overflow: hidden;
}

.p-datatable .p-datatable-thead > tr > th {
	background-color: #e2e6e9;
}

.p-datatable .p-datatable-tbody > tr {
	background-color: #c3cedb3b;
}

.subdata {
	padding: 1rem;
	padding-bottom: 0;
	border-radius: 5px;
}

.subdata-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.subdata > h5 {
	border-bottom: 1px solid #c3cedb;
	margin: 0;
	padding-bottom: 1rem;
}

.subdata-list li {
	padding: 1rem 0;
	border-bottom: 1px solid #c3cedb;
}

.subdata-list li:last-child {
	border-bottom: none;
}

.p-datatable-row-expansion > td {
	background-color: white;
}

@media (min-width: 1200px) {
	.p-dialog-mask {
		right: 0.5rem;
		z-index: 1101;
		width: calc(100% - 20.5rem);
		left: auto;
		border-radius: 1rem;
		top: 0.5rem;
	}
}

@media (min-width: 1400px) {
	.p-dialog-mask {
		right: 0.5rem;
		z-index: 1101;
		width: calc(100% - 25.5rem);
		left: auto;
		border-radius: 1rem;
		top: 0.5rem;
	}
}
.location-table-field {
	font-size: 0.85rem;
}

.p-inputswitch {
	width: 2.75rem !important;
	height: 1.25rem !important;
	margin-top: 6px !important;
}

.p-inputswitch-slider:before  {
	width: 1rem !important;
	height: 1rem !important;
	margin-top: -0.525rem !important;
}

