.container-details {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.info-section {
	height: 100%;
	width: 100%;
}

.hero-container {
	width: 100%;
	height: 15rem;
	position: relative;
	border-radius: 1rem;
	overflow: hidden;
}

.hero {
	border-radius: 0.5rem;
	overflow: hidden;
	position: relative;
	height: 100%;
	width: 100%;
	background-size: 90%;
	background-position: -170% 10%;
	background-repeat: no-repeat;
	z-index: 2;
}

.hero-color {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	background-image: linear-gradient(120deg, white  10%, var(--color-dark-green) 80%);
}

.hero-color-smoothie {
	background-image: linear-gradient(120deg, white  10%, var(--color-smoothie) 80%);
}

.hero-color-soup {
	background-image: linear-gradient(120deg, white  10%, var(--color-soup) 80%);
}

.hero-color-frappe {
	background-image: linear-gradient(120deg, white  10%, var(--color-frappe) 80%);
}

.hero-color-combo {
	background-image: linear-gradient(120deg, white  10%, var(--color-smoothie) 80%);
}

.hero-content {
	position: absolute;
	top: 2rem;
	right: 2rem;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: flex-end;
	z-index: 3;
}

.hero-content h1, .hero-content p {
	color: white;
	text-align: right;
	
}

.hero-content h1 {
	font-size: 1.8rem;
}

.smoothie {
	background-image: url('../assets/machines/smoothie.png');
}	

.soup {
	background-image: url('../assets/machines/soup.png');
	
}

.frappe {
	background-image: url('../assets/machines/frappe.png');
}

.combo {
	background-image: url('../assets/machines/combo.png');
}

.general-info {
	width: 100%;
	margin-top: 2rem;
	margin-bottom: 4rem;
	border-radius: 5px;
	min-width: 350px;
	width: 100%;
}

h2 {
	border-bottom: 1px solid #e7e7e7;
	padding-bottom: 1rem;
	font-size: 1.4rem;
}

.container-form {
	width: 100%;
	border-radius: 5px;
}

.ui.toggle.checkbox {
	min-height: 2.5rem;
	display: flex;
	align-items: center;
}

.info-flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 1rem;
	font-size: 1rem;
	padding: 0.25rem 0;
	border-radius: 5px;
	color: var(--color-dark-grey);
}

.info-flex span:first-child,
.info-flex-location-title {
	font-weight: bold;
}

.info-flex-location {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	font-size: 1rem;
	background-color: #e0e0e0;
	padding: 0.75rem 0.5rem;
	border-radius: 5px;
}

.info-flex-content {
	display: flex;
	align-items: center;
}

.info-flex-content span {
	margin-right: 0.5rem;
}

.info-flex-location-edit {
	cursor: pointer;
	margin-right: 0.5rem;
	padding: 0.25rem;
	border-radius: 50%;
	transition: all 0.3s ease-in-out;
}

.info-flex-location-edit:hover {
	background-color: #ccc;
	color: #fff;
}

@media (min-width: 1000px) {
	.info-section {
		border-right: 1px solid #e7e7e7;
		padding-right: 1rem;
	}

	.container-details {
		display: grid;
		grid-template-columns: 1fr 3fr;
		grid-template-rows: 1fr;
		grid-gap: 1rem;
		align-items: flex-start;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	.general-info {
		margin-top: 2;
	}

	.container-form {
		position: relative;
		width: 100%;
	}
}

.copy-id-flex {
	display: flex;
	align-items: center;
}

.copy-id-flex #machineId {
	margin-right: 0.5rem;
	font-weight: normal;
}

.copy-icon {
	cursor: pointer;
}

@media (min-width: 1400px) {
	.container-details {
		display: grid;
		grid-template-columns: 1fr 4fr;
		grid-template-rows: 1fr;
		grid-gap: 1rem;
		align-items: flex-start;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	.general-info {
		flex-basis: 40%;
		/* height: 100%; */
		margin-top: 2;
	}

	.container-form {
		position: relative;
		width: 100%;
	}
}
