.recipe-template-container {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
}

.recipe-template-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.recipe-template-image--fallback {
    height: 70%;
    width: 70%;
    background-color: var(--color-dark-grey);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 0.5;
}

.recipe-template-name {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}

.recipe-template-content-container {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 15rem;
}

.recipe-template-description-container-description {
    font-size: 12px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.recipe-template-label-container {
    width: 50px;
}

.recipe-template-label-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.recipe-template-label-container span {
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
}

.recipes-template-categories {
    font-size: 9px;
	font-weight: 400;
	padding: 1px 4px;
    border-radius: 4px;
    text-transform: capitalize;
    margin-right: 5px;
}

.recipe-ingredient-template-image {
    width: 25px;
    height: 25px;
    object-fit: cover;
    border-radius: 50%;
}

.recipe-ingredient-template-image--fallback {
    width: 27px;
    height: 27px;
    background-color: var(--color-light-grey);
    border-radius: 50%;
    font-size: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-dark-grey);
    line-height: 1;
    text-align: center;
}


