.main-datatable {
	border-right: 1px solid #c3cedbb0;
	border-left: 1px solid #c3cedbb0;
	border-radius: 10px;
	overflow: hidden;
}

.p-datatable .p-datatable-thead > tr > th {
	background-color: #e2e6e9;
}

.p-datatable .p-datatable-tbody > tr {
	background-color: #c3cedb3b;
}

.subdata {
	padding: 1rem;
	padding-bottom: 0;
	border-radius: 5px;
}

.subdata-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.subdata > h5 {
	border-bottom: 1px solid #c3cedb;
	margin: 0;
	padding-bottom: 1rem;
}

.subdata-list li {
	padding: 1rem 0;
	border-bottom: 1px solid #c3cedb;
}

.subdata-list li:last-child {
	border-bottom: none;
}

.p-datatable-row-expansion > td {
	background-color: white;
}

@media (min-width: 1200px) {
	.p-dialog-mask {
		right: 0.5rem;
		z-index: 1101;
		width: calc(100% - 20.5rem);
		left: auto;
		border-radius: 1rem;
		top: 0.5rem;
	}
}

@media (min-width: 1400px) {
	.p-dialog-mask {
		right: 0.5rem;
		z-index: 1101;
		width: calc(100% - 25.5rem);
		left: auto;
		border-radius: 1rem;
		top: 0.5rem;
	}
}
.location-table-field {
	font-size: 0.85rem;
}

.p-inputswitch {
	width: 2.75rem !important;
	height: 1.25rem !important;
	margin-top: 6px !important;
}

.p-inputswitch-slider:before  {
	width: 1rem !important;
	height: 1rem !important;
	margin-top: -0.525rem !important;
}
