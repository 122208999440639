:root {
	--color-dark-green: #005c64;
	--color-light-green: #00a19a;
	--color-light-red: #ff6b6b;
	--color-dark-grey: #5e5e5e;
	--color-light-grey: #e5e5e5;
	--color-black: #000000;
	--color-soup: #fc7033;
	--color-smoothie: #005c64;
	--color-frappe: #7C463C;
}
